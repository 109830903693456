import { Options, Vue } from "vue-class-component";
import TablePageItems from "@/entity/TablePageItems";

@Options({
    name: "app-table-page",
    props: {
        pageItems: {
            type: TablePageItems,
            default: () => new TablePageItems(),
        },
        pageFunction: Function,
    },
})
export default class TablePage extends Vue {
    private pageItems: TablePageItems = new TablePageItems();
    private pageFunction: any;

    private btnFirstPage() {
        if (!this.disFirstBtn) this.pageFunction(1);
    }
    private btnLastPage() {
        if (!this.disLastBtn) this.pageFunction(this.pageItems.pages);
    }
    private btnUpPage() {
        if (!this.disUpBtn) {
            let page = this.pageItems.current - 1;
            if (page < 1) page = 1;
            this.pageFunction(page);
        }
    }
    private btnDownPage() {
        if (!this.disDownBtn) {
            let page = this.pageItems.current + 1;
            if (page > this.pageItems.pages) page = this.pageItems.pages;
            this.pageFunction(page);
        }
    }

    get disFirstBtn() {
        if (this.pageItems.total <= 0) return true;
        return this.pageItems.current <= 1;
    }
    get disLastBtn() {
        if (this.pageItems.total <= 0) return true;
        return this.pageItems.current >= this.pageItems.pages;
    }
    get disUpBtn() {
        if (this.pageItems.total <= 0) return true;
        return this.pageItems.current <= 1;
    }
    get disDownBtn() {
        if (this.pageItems.total <= 0) return true;
        return this.pageItems.current >= this.pageItems.pages;
    }
}
